import { getSubDomain, getIsTeams } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useOrganization,
} from '@guider-global/sanity-hooks';
import { Avatar, Header, theme } from '@guider-global/ui';
import { Typography } from '@mui/material';
import { Box, ThemeProvider, useTheme } from '@mui/system';
import { LanguageMenu, PageWrapper } from 'components';
import { RegistrationContainer } from 'containers';
import { useLocalization } from 'hooks';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { hideNavbar, showNavbar } from 'store/slices/appSlice';
import { deepMerge } from 'utils';

export function RegistrationPage() {
  const organizationSlug = getSubDomain();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(hideNavbar(true));
    return () => {
      dispatch(showNavbar(true));
    };
  }, [dispatch]);

  const { organization } = useOrganization({
    organizationSlug,
  });

  const { localeCode, handleLanguageChange } =
    useLocalization(organizationSlug);

  const organizationTheme = useTheme();
  const combinedPalette = deepMerge(
    theme.appTheme.palette,
    organizationTheme.palette,
  );
  const isTeams = getIsTeams();

  if (isTeams) {
    return (
      <PageWrapper>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            component="img"
            src="https://cdn.sanity.io/images/wrhtm9cw/production/e7a9eb64dc769fe9b9bfd8b3b0d65b415c972a13-512x512.png"
            sx={{ width: '300px' }}
          />
          <Typography sx={{ textAlign: 'center', mt: 10 }} variant="h5">
            Don’t have a mentoring program set up at your company yet?
            <br />
            Don’t worry, we can help, just email{' '}
            <Typography
              component="a"
              variant="h4"
              href="mailto:hello@guider-ai.com"
            >
              hello@guider-ai.com
            </Typography>{' '}
            with “get mentoring”.
          </Typography>
        </Box>
      </PageWrapper>
    );
  }

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette: combinedPalette }}>
      <Header
        bgColor={combinedPalette.common.white}
        logo={
          <Avatar
            src={buildSanityImageUrl({
              source: organization?.white_label?.auth0_logo ?? '',
            })}
            sx={{
              maxHeight: '130px',
              maxWidth: '130px',
              border: 'hidden',
            }}
          />
        }
        onBackButtonClick={() => navigate(-1)}
        endAdornment={
          <LanguageMenu
            arrowColor={'black'}
            localeCode={localeCode}
            languageOptions={organization?.organizationLanguages ?? []}
            ariaLabel={'Change Language Dropdown'}
            onClickChange={handleLanguageChange}
          />
        }
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flex: '1 1 0',
          justifyContent: 'center',
          backgroundColor: combinedPalette.common.white,
          py: { xs: 0, md: 3 },
        }}
      >
        <RegistrationContainer />
      </Box>
    </ThemeProvider>
  );
}
